import React from 'react';
import type { FC, ReactNode } from 'react';
import styled from 'styled-components';
import { DynamicImage, Text } from 'components';
import ContentContainer from './ContentContainer';
import StartNowButton from './StartNowButton';
import { mobile, useQuery } from 'styles/breakpoints';

interface DescriptionProps {
  title: string;
  subtitle?: string;
  image?: string;
  onClick?: () => void;
  children?: ReactNode;
  mobileSmTitle?: boolean;
  mobileCenterTitle?: boolean;
  startBtnTitle?: string;
}

const Container = styled(ContentContainer)`
  margin-bottom: 6rem;
  display: flex;
  justify-content: center;
  @media ${mobile} {
    margin-bottom: 3rem;
  }
`;

const TitleContainer = styled.div<{ mobileCenter?: boolean }>`
  margin-bottom: 2.5rem;

  @media ${mobile} {
    margin-bottom: 1.5rem;
    text-align: ${({ mobileCenter }) => (mobileCenter ? 'center' : 'left')};
  }
`;

const Subtitle = styled(Text).attrs({
  color: 'dark100',
})`
  margin-top: 1rem;
  text-align: center;
`;

const Button = styled(StartNowButton)`
  margin: 3rem auto 0 auto;
  @media ${mobile} {
    margin-top: 3rem;
  }
`;

const Title = styled(Text)`
  text-align: left;
  font-size: 1.25rem;
  font-weight: 700;
  @media ${mobile} {
    text-align: center;
  }
`;

const RightSide = styled.div``;
const Description: FC<DescriptionProps> = ({
  title,
  subtitle,
  image,
  onClick,
  children,
  mobileSmTitle,
  mobileCenterTitle,
  startBtnTitle,
}) => {
  const { isMobile } = useQuery();
  return (
    <Container>
      {isMobile ? null : (
        <DynamicImage
          alt=""
          src={image || ''}
          maxWidth="28.125rem"
          marginRight="4rem"
          alignCenter={true}
        />
      )}
      <RightSide>
        <TitleContainer mobileCenter={mobileCenterTitle}>
          <Title color="dark100">{title}</Title>
          {subtitle && (
            <Subtitle type={isMobile ? 'body' : 'h4400'}>{subtitle}</Subtitle>
          )}
        </TitleContainer>

        {children}
        {onClick && startBtnTitle ? (
          <Button onClick={onClick}>{startBtnTitle}</Button>
        ) : null}
      </RightSide>
    </Container>
  );
};

export default React.memo(Description);
