import styled from 'styled-components';
import { mobile, smLaptop } from 'styles/breakpoints';

const Container = styled.div`
  max-width: 71.25rem;
  margin: 4rem auto;

  @media ${smLaptop} {
    margin: 4rem 1rem;
  }

  @media ${mobile} {
    margin: 2rem 1rem;
  }
`;

export default Container;
