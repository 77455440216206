import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

interface HeadingSectionProps {
  title: string;
  label: string;
}

const Container = styled.div`
  padding: 3rem 1rem 2.25rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  @media ${mobile} {
    padding: 1rem;
  }
`;

const Title = styled(Text)`
  color: #c1c1f2;
  text-align: center;
  max-width: 48rem;
`;

const Label = styled(Text)`
  text-align: center;
  max-width: 48rem;
  @media ${mobile} {
    font-size: 1.625rem;
  }
`;

const HeadingSection: FC<HeadingSectionProps> = ({ title, label }) => {
  const { isMobile } = useQuery();
  return (
    <Container>
      <Title type={isMobile ? 'h3' : 'count'}>{title}</Title>
      <Label type="h13Rem">{label}</Label>
    </Container>
  );
};

export default HeadingSection;
