import React, { FC } from 'react';
import styled from 'styled-components';

import { Text } from 'components';
import { mobile, useQuery } from 'styles/breakpoints';

interface SubscriptionPlanProps {
  priceText: string;
  monthPlanText: string;
  perDayPrice: string;
  perDayText: string;
  finalPrice: string;
  finalText: string;
  isNew?: boolean;
  subscriptionPercentage?: number;
}

interface TextProps {
  isNew?: boolean;
}

const Wrapper = styled.div<TextProps>`
  display: flex;
  flex-direction: column;
  margin-right: ${({ isNew }) => (isNew ? '0' : '1rem')};
`;

const SubscriptionContainer = styled.div<TextProps>`
  z-index: 1;
  width: 16rem;
  border-radius: 16px;
  background-color: ${({ theme }) => theme.colors.light0};
  padding: 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${({ isNew, theme }) =>
    isNew ? `2px solid ${theme.colors.green};` : ''};
  @media ${mobile} {
    width: 100%;
  }
`;

const PriceText = styled(Text)<TextProps>`
  color: ${({ isNew, theme }) => theme.colors[isNew ? 'green' : 'dark40']};
`;

const MonthPlanText = styled(Text)<TextProps>`
  margin-bottom: 1rem;
  color: ${({ isNew, theme }) => theme.colors[isNew ? 'dark60' : 'dark40']};
`;

const PerDayText = styled(Text)<TextProps>`
  margin-bottom: 1rem;
  color: ${({ isNew, theme }) => theme.colors[isNew ? 'dark100' : 'dark40']};
  display: flex;
  align-items: flex-end;
  font-size: 1.5rem;
  @media ${mobile} {
    font-size: 1rem;
  }
  span {
    font-size: 2.25rem;
    font-weight: 700;
    line-height: 2rem;
    text-decoration: ${({ isNew }) => (isNew ? 'none' : 'line-through')};
    @media ${mobile} {
      font-size: 1.5rem;
      line-height: 1.3rem;
    }
  }
`;

const BilledEvery = styled(Text)<TextProps>`
  color: ${({ isNew, theme }) => theme.colors[isNew ? 'dark100' : 'dark40']};
  text-align: center;
  max-width: 8.6rem;
  span {
    font-weight: 700;
    text-decoration: ${({ isNew }) => (isNew ? 'none' : 'line-through')};
  }
`;

const SaveBlock = styled.div`
  height: 4rem;
  top: 2rem;
  position: relative;
  margin-top: -2rem;
  border-radius: 1rem;
  width: 100%;
  display: flex;
  justify-content: center;
  padding-top: 0.66rem;
  background-color: ${({ theme }) => theme.colors.green};
`;

const SaveText = styled(Text)``;

const SubscriptionPlan: FC<SubscriptionPlanProps> = ({
  priceText,
  monthPlanText,
  perDayPrice,
  perDayText,
  finalPrice,
  finalText,
  isNew,
  subscriptionPercentage,
}) => {
  const { isMobile } = useQuery();
  return (
    <Wrapper isNew={isNew}>
      {isNew ? (
        <SaveBlock>
          <SaveText type="body700" color="light0">
            {`SAVE ${subscriptionPercentage}%`}
          </SaveText>
        </SaveBlock>
      ) : null}
      <SubscriptionContainer isNew={isNew}>
        <PriceText type={isMobile ? 'body700' : 'h3'} isNew={isNew}>
          {priceText}
        </PriceText>
        <MonthPlanText type={isMobile ? 'bodyM' : 'body'} isNew={isNew}>
          {monthPlanText}
        </MonthPlanText>
        <PerDayText isNew={isNew}>
          <span>{perDayPrice}</span>
          {perDayText}
        </PerDayText>
        <BilledEvery type={isMobile ? 'bodyM' : 'body'} isNew={isNew}>
          <span>{finalPrice}</span> {' ' + finalText}
        </BilledEvery>
      </SubscriptionContainer>
    </Wrapper>
  );
};

export default SubscriptionPlan;
