import React, { useCallback } from 'react';
import type { FC } from 'react';
import styled from 'styled-components';
import { tablet, useQuery } from 'styles/breakpoints';
import { DynamicImage, Text } from 'components';

interface ListItem {
  img: string;
  title: string;
  text: string;
}

interface IconListProps {
  data: ListItem[];
}

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  > div:nth-child(1n):not(:last-child) {
    margin-right: 1rem;
  }

  @media ${tablet} {
    flex-direction: column;

    > div:nth-child(1n):not(:last-child) {
      margin-right: 0rem;
    }
  }
`;

const ItemContainer = styled.div`
  display: flex;
  padding-bottom: 1rem;
  flex-direction: row;
  max-width: 322px;
  align-items: center;

  @media ${tablet} {
    flex-direction: row;
    width: auto;
  }
`;

const ImageContainer = styled.div``;

const StyledDynamicImage = styled(DynamicImage)`
  object-fit: contain;
  width: 3rem;
  height: 3rem;
  margin-right: 0.5rem;
  height: 48px;
  width: 48px;
`;

const TextStyled = styled(Text).attrs({
  color: 'dark80',
})`
  margin-top: 0.25rem;
  width: 330px;
  -webkit-text-size-adjust: 100%;
  @media ${tablet} {
    width: 271px;

    margin-top: 0.25rem;
  }
`;

const IconList: FC<IconListProps> = ({ data }) => {
  const { isMobile } = useQuery();

  const renderItem = useCallback((item: ListItem, index: number) => {
    const key = `description-2-item-${index}`;

    return (
      <ItemContainer key={key}>
        <ImageContainer>
          <StyledDynamicImage src={item.img} alt={item.title} />
        </ImageContainer>
        <div>
          <Text
            fontSize={isMobile ? 0.875 : 1.125}
            fontWeight={'700'}
            color="dark80"
          >
            {item.title}
          </Text>
          <TextStyled fontSize={isMobile ? 0.75 : 0.875}>
            {item.text}
          </TextStyled>
        </div>
      </ItemContainer>
    );
  }, []);
  return <Container>{data.map(renderItem)}</Container>;
};

export default React.memo(IconList);
